.i-name {
    margin: 0 0 10px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #777;
    /* border-bottom: 3px solid #888; */
}

.i-info {
    font-size: 1rem;
    margin: 20px 0;
}

.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
    font-size: 0.85rem;
    color: #666;
}
/* 
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-8 {
    margin-top: 24px;
} */

.css-1tw2i9j-MuiGrid-root > .MuiGrid-root.MuiGrid-container.MuiGrid-item.MuiGrid-spacing-xs-2.MuiGrid-grid-xs-12 {
    padding-top: 0;
}

#il-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.l-page {
    height: 30px;
    margin-top: 20px;
}

.li-div {
    height: 300px;
    max-width: 500px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    justify-content: center;
}

.ii-item {
    background-color: rgba(32, 52, 94, 0.13);
    width: fit-content;
    padding: 3px 10px;
    margin-bottom: 10px;
    font-size: 0.9em;
}

.info-ci {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}

@media only screen and (max-width: 992px) {
    .info-ci {
        display: inline-block;
    }

    .info-ci > .li-div {
        display: none;
    }
}

@media only screen and (max-width: 576px) {
    .info-ci.not-finish {
        display: none;
    }
}
