.eidg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100px;
}

input#eidg {
    border: none;
    padding: 0;
    background: repeating-linear-gradient(90deg, #bbb 0, #bbb 1ch, transparent 0, transparent 1.5ch) 0 100%/ 8.5ch 2px no-repeat;
    font: 5ch droid sans mono, consolas, monospace !important;
    letter-spacing: 0.5ch;
    position: absolute;
    left: 22ch;
    top: 0;
    /* caret-color: transparent; */
}

input#eidg:focus {
    outline: none;
}

input#eidg + span {
    position: absolute;
    letter-spacing: 0.5ch;
    font: 5ch droid sans mono, consolas, monospace !important;
    left: 25ch;
    color: #888;
    top: 0;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

input#eidg + span > span {
    font-family: sans-serif !important;
    font-size: 0.5ch;
    letter-spacing: 0.05ch;
    position: absolute;
    right: 0;
    left: -11ch;
    width: fit-content;
    top: 120%;
    opacity: 0.8;
}

@media only screen and (max-width: 1399px) {
    input#eidg {
        left: 17ch;
    }
    input#eidg + span {
        left: 20ch;
    }
}

@media only screen and (max-width: 1199px) {
    input#eidg {
        left: 13ch;
    }
    input#eidg + span {
        left: 16ch;
    }
}

@media only screen and (max-width: 991px) {
    input#eidg {
        left: 9ch;
    }
    input#eidg + span {
        left: 12ch;
    }
}

@media only screen and (max-width: 767px) {
    input#eidg {
        left: 1ch;
    }
    input#eidg + span {
        left: 4ch;
    }
}
