.input_div {
    position: relative;
}

.input_div svg {
    position: absolute;
    top: 33px;
    right: 10px;
    cursor: pointer;
}

.input_div input {
    padding-right: 44px;
}

.hrp-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20000000;
    background-color: transparent;
    cursor: wait !important;
}

.login-logo-container {
    width: 58.333333%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .login-logo-container {
        display: none;
    }
}
